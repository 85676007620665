// import Config from '@/config';
import moment from 'moment';
import accountApi from '../api/account-api';
import i18n from '../../../i18n/index';

// helpers
const setCachedPreferences = (data, emailData) => {
  localStorage.setItem('cachedPreferences', JSON.stringify({
    datetime: new Date(),
    data,
    emailData,
  }));
};

// initial state
const defaultState = {
  userFilters: {},
  userPreferences: {},
  userEmailPreferences: {},
  organizationPreferences: {},
};

const state = defaultState;

// getters
const getters = {
  userFilters: (state) => state.userFilters,
  userPreferences: (state) => state.userPreferences,
  userEmailPreferences: (state) => state.userEmailPreferences,
  organizationPreferences: (state) => state.organizationPreferences,
};

// actions
const actions = {

  async getPreferences({ commit, dispatch }, { skipCache = false } = {}) {
    // First check cached preferences data:
    if (!skipCache) {
      const cachedData = localStorage.getItem('cachedPreferences');
      if (cachedData) {
        const { datetime, data, emailData } = JSON.parse(cachedData);
        // Use cache if max 1 hour old
        const cacheTime = (new Date(datetime)).getTime();
        const cacheTimeLimit = (new Date()).getTime() - 60 * 60 * 1000;
        if (cacheTime > cacheTimeLimit) {
          commit('setUserPreferences', data.user);
          dispatch('setSearchParametersLocale', data.user.locale);
          commit('setOrganizationPreferences', data.organization);
          commit('setUserEmailPreferences', emailData.emailConfig);
          return data;
        }
      }
    }
    const { data } = await accountApi.getPreferences();
    commit('setUserPreferences', data.user);
    dispatch('setSearchParametersLocale', data.user.locale);
    commit('setOrganizationPreferences', data.organization);

    const { data: emailData } = await accountApi.getEmailPreferences();
    commit('setUserEmailPreferences', emailData.emailConfig);

    setCachedPreferences(data, emailData);

    return data;
  },

  async setPreferences({ commit, dispatch }, preferences) {
    // Wipe the preferences from localStorage
    await localStorage.removeItem('cachedPreferences');
    const { data } = await accountApi.updatePreferences(preferences);
    commit('setUserPreferences', data.user);
    dispatch('setSearchParametersLocale', data.user.locale);
    commit('setOrganizationPreferences', data.organization);

    // It seems we need to do this as well otherwise it will cause errors in getPreferences
    const { data: emailData } = await accountApi.getEmailPreferences();
    commit('setUserEmailPreferences', emailData.emailConfig);

    // Set the preferences in localStorage
    setCachedPreferences(data, emailData);

    return data;
  },

  async setEmailPreferences({ commit }, preferences) {
    // Wipe the preferences from localStorage
    await localStorage.removeItem('cachedPreferences');

    const { data: emailData } = await accountApi.updateEmailPreferences(preferences);
    commit('setUserEmailPreferences', emailData.emailConfig);

    const { data } = await accountApi.getPreferences();
    commit('setUserPreferences', data.user);
    commit('setOrganizationPreferences', data.organization);

    // Set the altered preferences in localStorage
    setCachedPreferences(data, emailData);

    return emailData;
  },

  async getDocumentFilterTypesForUser({ commit }, { skipCache = false } = {}) {
    // First check cached filters:
    if (!skipCache) {
      const cachedFilters = localStorage.getItem('getDocumentFilterTypesForUserCache');
      if (cachedFilters) {
        const { datetime, filters } = JSON.parse(cachedFilters);
        // Use cache if max 10 minutes old
        const cacheTime = (new Date(datetime)).getTime();
        const cacheDurationMinutes = (new Date()).getTime() - 10 * 60 * 1000;
        const cacheTimeLimit = cacheDurationMinutes;
        if (cacheTime > cacheTimeLimit) {
          commit('updateFilterTypesForUser', filters);
          return filters;
        }
      }
    }

    // If not cached then fetch fresh results:
    // TODO merge these 3 request into 1, as we always do them together
    const [nlResponse, euResponse, nlLrResponse] = await Promise.all([
      accountApi.getNlGroupingForUser(),
      accountApi.getEuGroupingForUser(),
      accountApi.getNlLrGroupingForUser(),
    ]);

    const {
      nlContexts,
      nlGrouping,
      nlGroupsByDocType,
      documentTypesConfig,
    } = nlResponse.data;
    const {
      euGrouping,
      euGroupsByDocType,
      euEpCommittees,
    } = euResponse.data;
    const {
      nlLrGrouping,
      nlLrGroupsByDocType,
    } = nlLrResponse.data;

    const filters = {
      context: nlContexts,
      nlGrouping,
      nlGroupsByDocType,
      documentTypesConfig,
      euGrouping,
      euGroupsByDocType,
      euEpCommittees,
      nlLrGrouping,
      nlLrGroupsByDocType,
    };

    // Store in cache
    localStorage.setItem('getDocumentFilterTypesForUserCache', JSON.stringify({
      datetime: new Date(),
      filters,
    }));

    commit('updateFilterTypesForUser', filters);

    return filters;
  },

  async getAgendaFilterTypesForUser({ commit }) {
    const response = await accountApi.getAgendaFiltersForUser();
    const filters = { agenda: response.data };
    commit('updateFilterTypesForUser', filters);
    return filters;
  },

  async getStakeholderSourceList() {
    return accountApi.getStakeholderSourceList();
  },

  async sendWebCalInviteMail() {
    const response = await accountApi.sendWebCalInviteMail();
    return response.data;
  },
};

// mutations
const mutations = {
  setUserPreferences(state, preferences) {
    // Save theme setting in localStorage
    localStorage.setItem('uiDarkMode', JSON.stringify(preferences.uiDarkMode));
    localStorage.setItem('drawerLeftWidth', JSON.stringify(preferences.drawerLeftWidth));
    state.userPreferences = preferences;

    if (preferences.locale) {
      // Note: the locale is saved in the localStorage including the quotes, "en"
      // so we need to parse it again to get the actual string.
      localStorage.setItem('locale', JSON.stringify(preferences.locale));
      i18n.locale = preferences.locale;
      moment.locale(preferences.locale);
    }
  },
  setUserEmailPreferences(state, preferences) {
    state.userEmailPreferences = preferences;
  },
  setOrganizationPreferences(state, preferences) {
    state.organizationPreferences = preferences;
  },
  updateFilterTypesForUser(state, filters) {
    state.userFilters = {
      ...state.userFilters,
      ...filters,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
