import Vue from 'vue';
import VueI18n from 'vue-i18n';

import accountTranslations from './account';
import agendaDashboardTranslations from './agendaDashboard';
import listTranslations from './listManager';
import documentDashboardTranslations from './documentDashboard';
import sharedTranslations from './shared';
import supportTips from './supportTips';


const languages = {
  en: {
    ...accountTranslations.en,
    ...agendaDashboardTranslations.en,
    ...listTranslations.en,
    ...documentDashboardTranslations.en,
    ...sharedTranslations.en,
    ...supportTips.en,
  },
  nl: {
    ...accountTranslations.nl,
    ...agendaDashboardTranslations.nl,
    ...listTranslations.nl,
    ...documentDashboardTranslations.nl,
    ...sharedTranslations.nl,
    ...supportTips.nl,
  },
};

const messages = { ...languages };

Vue.use(VueI18n);

export default new VueI18n({
  locale: JSON.parse(localStorage.getItem('locale')),
  fallbackLocale: 'nl',
  messages,
});
