<!--
  Component which displays messages and errors
-->

<template>
  <div>
    <v-snackbar
      v-model="showMessage"
      v-if="messageData"
      :color="messageData.type"
      :timeout="4000"
      multi-line
    >
      {{ messageData ? messageData.message : '' }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showMessage = false"
        >
          {{ $t('generic.close')}}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MessageBar',

  computed: mapGetters({
    messageData: 'getMessage',
  }),

  data() {
    return {
      showMessage: false,
    };
  },

  watch: {
    messageData() {
      this.showMessage = true;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
