<template>
  <v-dialog
    v-model="dialog"
    max-width="480"
    @click:outside="hideForm()"
    v-if="dialogConfirmDialogVisible"
  >
    <v-card>
      <v-card-title>
        <v-container class="ma-0 pa-0">
          <v-row>
            <v-col class="d-flex mt-n2">
              <div
                class="d-inline-block align-self-start text-subtitle-1
              "
                style="width: 80%"
              >
                {{ confirmDialogHeader }}
              </div>
              <div
                class="d-inline-block text-right align-self-start"
                style="width: 20%"
              >
                <v-icon
                  class="mt-n2 mr-n2"
                  @click="hideForm()"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        {{ confirmDialogBody }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="hideForm()"
        >
          {{$t('generic.cancel')}}
        </v-btn>

        <v-btn
          class="white--text"
          color="teal lighten-1"
          @click="confirmAction()"
        >
          {{$t('generic.confirm')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/event-bus';

export default {

  data() {
    return {
      dialog: true,
    };
  },

  props: {
    // visible: false,
  },

  computed: {
    ...mapGetters({
      confirmDialogHeader: 'confirmDialogHeader',
      confirmDialogBody: 'confirmDialogBody',
      dialogConfirmDialogVisible: 'dialogConfirmDialogVisible',
    }),
  },

  methods: {

    confirmAction() {
      // Alternative approach
      EventBus.$emit('submitConfirmDialog');
      this.$store.dispatch('setConfirmDialogVisible', false);
    },

    hideForm() {
      this.$store.dispatch('setConfirmDialogVisible', false);
      // Make sure we don't listen to unnecessary events
      EventBus.$off('submitConfirmDialog');
    },
  },
};
</script>
